
.integration {
    width: 100%;
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.courses-list {
    height: calc(100% - 20px);
    padding: 20px 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    .course-item {
        width: calc(50% - 17px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 198px;
        margin-bottom: 60px;
        &:nth-child(2n+1) {
            margin-right: 34px;
        }
        .course-cover {
            width: 140px;
            height: 100%;
            margin-right: 20px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .course-desc {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            h4 {
                margin: 0 0 17px 0;
                font-size: 16px;
                color: #333333;
            }
            p {
                margin: 0;
                padding: 0;
                font-size: 14px;
                color: #666666;
            }
        }
    }
}
.integration-course {
    padding: 20px 0;
    height: calc(100% - 20px);
    width: 1200px;
    margin: 0 auto;
    .integration-breadcrumb {
        border-bottom: 1px solid #EAEAEA;
        color: #666666;
        height: 30px;
    }
    .integration-course-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 20px 0;
            display: flex;
            justify-content: start;
            .banner {
                width: 140px;
                height: 198px;
                margin-right: 20px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            span {
                flex: 1;
                width: calc(100% - 160px);
            }
        }
    }
}
